<template>
  <v-container>
    <h3>Вакансии</h3>
    <p>Ниже вы видите вакансии в актуальных проектах.</p>
    <CardUI class="mt-2" :key="vacancy.id" v-for="vacancy in vacancies">
      <template v-slot:header>
        <h4>
          Требуется "{{ vacancy.name }}" в "{{ vacancy.project.title }}"
        </h4>
      </template>
      <template v-slot:body>
        <v-row  align="center">
          <v-col cols="12" md="auto">
            <v-img :src="vacancy.project.logotype_url" aspect-ratio="1" height="100" width="100"></v-img>
          </v-col>
          <v-col cols="12" md="auto">
            <div></div>
           <b>Требования к кандидату:</b> {{ vacancy.tasks }} <br/>
            <b>О проекте:</b>
            <p style="overflow: scroll">
              {{ vacancy.project.problem.trim() }} <br/>
              {{ vacancy.project.task.trim() }} <br/>
              {{ vacancy.project.result.trim() }} <br/>
            </p>
            <div>
              Уточнить информацию:
              <UserLinkComponent :user="vacancy.project.responsible_user" class="mt-3"/> <br/>
            </div>
          </v-col>
          <v-spacer/>
          <v-col cols="12" md="auto">

            <v-btn :to="'projects/'+vacancy.project.id" small elevation="0" color="primary" dark>Подать заявку</v-btn>
          </v-col>

        </v-row>
        <v-row>

        </v-row>


      </template>
    </CardUI>
  </v-container>
</template>

<script>
import vacancies from "@/api/vacancies";
import CardUI from "@/components/UI/CardUI";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import analytics from "@/api/analytics";

export default {
  name: "VacanciesListComponent",
  components: {CardUI, UserLinkComponent},
  data() {
    return {
      vacancies: []
    }
  },
  mounted() {
    vacancies.get().then((r) => {
      this.vacancies = r.data;
      analytics.openVacancies(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)
    })
  }
}
</script>

<style scoped>

</style>
